<template>
  <v-row>
    <v-col cols="12">
      <div class="caption">
        {{ $t('productDimensions') }}
      </div>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          cols="12"
          :md="newMd"
          class="grow"
        >
          <v-text-field
            type="number"
            :label="$t('length')"
            v-model="length"
            filled
            hide-details
          />
        </v-col>
        <v-col
          class="text-center"
          cols="12"
          :md="newMd"
        >
          x
        </v-col>
        <v-col
          cols="12"
          :md="newMd"
          class="grow"
        >
          <v-text-field
            type="number"
            :label="$t('width')"
            v-model="width"
            filled
            hide-details
          />
        </v-col>
        <v-col
          class="text-center"
          cols="12"
          :md="newMd"
        >
          x
        </v-col>
        <v-col
          cols="12"
          :md="newMd"
          class="grow"
        >
          <v-text-field
            type="number"
            :label="$t('height')"
            v-model="height"
            filled
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          :md="divWidth > 500 ? '3' : '12'"
        >
          <v-autocomplete
            :items="dimensions"
            v-model="selectedDimensionType"
            :no-data-text="$t('noResultsFound')"
            filled
            hide-details
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <div class="caption">
        {{ $t('productWeight') }}
      </div>
      <v-row>
        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            type="number"
            :label="$t('weight')"
            v-model="weight"
            filled
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            :items="weights"
            v-model="selectedWeightType"
            :no-data-text="$t('noResultsFound')"
            filled
            hide-details
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-switch
        :label="$t('fragile')"
        v-model="isFragile"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
	name: "Shipping",
	data() {
		return {
			length: null,
			width: null,
			height: null,
			weight: null,
			selectedWeightType: 'lb',
			selectedDimensionType: 'mm',
			isFragile: false,
		}
	},
	computed: {
		weights() {
			return ['lb', 'oz', 'kg', 'g']
		},
		dimensions() {
			return ['in', 'ft', 'mm', 'cm', 'm']
		},
		newMd() {
			if(this.divWidth > 500) {
				return 'auto'
			} else {
				return '12'
			}
		},
		divWidth() {
			return document.getElementById('item-form').offsetWidth
		}
	}
}
</script>

<style scoped>

</style>